import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { getLocaleByCode } from 'modules/utils/locales';
import { Link } from 'components/ui';
import environment from 'modules/utils/environment';
import useI18n from 'hooks/I18n/useI18n';
import { getChristmasLogoForEnabledCountries } from 'modules/logo';

const SimpleNavigation = ({ className, hideOnMobile, isPaymentPage, isReferralPage }) => {
    const { i18n, trans } = useI18n();
    const locale = getLocaleByCode(i18n);
    let logo = locale.logos.whiteOutlined;

    logo = getChristmasLogoForEnabledCountries(logo, locale);

    const homepage =
        environment === 'production' && (isPaymentPage || isReferralPage)
            ? `${locale.homepage}/${i18n}`
            : `/${i18n}`;

    return (
        <div
            className={classnames('topNavigation topNavigation--relative', className, {
                'hidden-xs': hideOnMobile,
                'topNavigation-withoutShadow': isReferralPage,
            })}
        >
            <div className="topNavigation-grid">
                <Link href={homepage} data-bypass>
                    <img className="topNavigation-logo" alt={trans('brand.name')} src={logo} />
                </Link>
            </div>
        </div>
    );
};

SimpleNavigation.propTypes = {
    className: PropTypes.string,
    hideOnMobile: PropTypes.bool,
    isPaymentPage: PropTypes.bool,
    isReferralPage: PropTypes.bool,
};

export default SimpleNavigation;
