export function isOnSalePage(url) {
    return (
        /assemblies\/\d+(\/collections\/\d+)?\/products/i.test(url) ||
        /assemblies\/\d+(\/collections\/\d+)?\/guests/i.test(url)
    );
}

export function isOnAssemblyHomepage(url) {
    return /assemblies\/\d+\/?$/i.test(url);
}

export const isOnDeliveryPage = url => {
    return (
        /orders(\/\d+)?\/pickup/i.test(url) ||
        /checkout(\/[a-f0-9-]+)?\/pickup/i.test(url) ||
        /complete-profile/i.test(url)
    );
};

export const isOnBasketPage = url => {
    return /basket/i.test(url);
};

export const isOnCheckoutAccountPage = url => {
    return /checkout(\/[a-f0-9-]+)?\/(pickup|account|login)/i.test(url);
};

export const isOnMyAccountPage = url => {
    return /configuration\/account/i.test(url);
};

export const isOnBecameHost = url => {
    return /validate\/scu\/host/i.test(url);
};

export const isOnBecameProducer = url => {
    return /validate\/scu\/producer/i.test(url);
};

export const isOnMap = url => {
    return /assemblies+$/i.test(url);
};
