import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import InlineSVG from 'svg-inline-react';
import { useParams } from 'react-router-dom';
import {
    fetchOrders,
    fetchOrdersAndValidateBasket,
    getOrders,
    isInitialLoad as isInitialLoadSelector,
    isOrdersCollectionLoading as isLoadingSelector,
    isOrdersCollectionLoaded,
} from 'modules/orders';
import { fetchHive } from 'api/hives';

import AppChannel from 'modules/channels/App';
import {
    currentDistributionSelector,
    currentAssemblySelector,
    switchDistribution,
} from 'modules/navigation';

import useAnalytics from 'hooks/Analytics/useAnalytics.js';
import useI18n from 'hooks/I18n/useI18n';
import useResponsive from 'hooks/Navigation/useResponsive';

import { deduplicatePendingOrders } from 'models/orders';
import FeaturesService from 'models/features';

import LoadingAnimation from 'components/LoadingAnimation.jsx';
import TermsFooter from 'components/ProductIdentity/TermsFooter.jsx';
import BasketOrder from 'components/Baskets/ProductIdentity/BasketOrder.jsx';
import OrderResetToCartModal from 'components/Baskets/ProductIdentity/OrderResetToCartModal.jsx';
import Heading from 'components/Heading.jsx';
import Banner from 'components/Banner.jsx';
import PIText from 'components/ProductIdentity/Text.jsx';
import CancelContainerModal from 'components/Baskets/ProductIdentity/CancelContainerModal.jsx';
import CheckoutTimeline, {
    STEP_BASKET,
} from 'components/Checkout/ProductIdentity/CheckoutTimeline.jsx';

import ProducerIllustrationSVG from 'app/assets/new-design/images/illustrations/producer-illustration.svg';
const BasketsContainer = () => {
    const { trans } = useI18n();
    const { analytics } = useAnalytics();
    const isSmallWidth = useResponsive();
    const dispatch = useDispatch();
    const isInitialLoad = useSelector(isInitialLoadSelector);
    const ordersCollectionLoaded = useSelector(isOrdersCollectionLoaded);
    const isLoading = useSelector(isLoadingSelector);
    const orders = useSelector(getOrders);
    const currentDistribution = useSelector(currentDistributionSelector);
    const currentAssembly = useSelector(currentAssemblySelector);
    const user = useSelector(state => state.currentUser.user);
    const [orderCancelModal, setOrderCancelModal] = useState(undefined);
    const [orderResetToCartModal, setOrderResetToCartModal] = useState(undefined);
    const standardizationDistributionId = useMemo(
        () => currentDistribution?.distributionId || currentDistribution?.id,
        [currentDistribution]
    );
    const { distributionId = standardizationDistributionId } = useParams();
    const sortedOrders = useMemo(
        () =>
            deduplicatePendingOrders(orders).sort((orderA, orderB) =>
                orderA.updatedAt < orderB.updatedAt ? 1 : -1
            ),
        [orders]
    );
    const currentOrder = useMemo(
        () => R.find(R.propEq('distributionId', parseInt(distributionId, 10)))(orders),
        [orders, distributionId]
    );

    const currentBasket = useRef(
        R.find(R.propEq('distributionId', distributionId))(sortedOrders) || sortedOrders?.[0]
    );

    const loadData = useCallback(() => {
        const anonymousBasketUuid = new URLSearchParams(window.location.search).get(
            'fromAnonymousBasket'
        );
        if (anonymousBasketUuid) {
            return dispatch(fetchOrdersAndValidateBasket(anonymousBasketUuid));
        }
        return dispatch(fetchOrders());
    }, [dispatch]);

    const openCancelOrderModal = useCallback(orderId => {
        setOrderCancelModal(orderId);
    }, []);

    const closeCancelOrderModal = useCallback(() => {
        setOrderCancelModal(undefined);
        loadData();
    }, [loadData]);

    const openResetToCartOrderModal = useCallback(orderId => {
        setOrderResetToCartModal(orderId);
    }, []);

    const closeResetToCartOrderModal = useCallback(() => {
        setOrderResetToCartModal(undefined);
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (!R.isEmpty(sortedOrders)) {
            currentBasket.current =
                R.find(R.propEq('distributionId', distributionId))(sortedOrders) ||
                sortedOrders?.[0];
            currentBasket.current &&
                fetchHive(currentBasket.current.distribution.hiveId).then(hive => {
                    dispatch(switchDistribution(hive, currentBasket.current.distribution));
                });
        }
    }, [distributionId, analytics, user, dispatch, sortedOrders]);

    useEffect(() => {
        if (ordersCollectionLoaded) {
            analytics.trackCartPageView(currentBasket.current);
        }
    }, [ordersCollectionLoaded, analytics, distributionId]);

    useEffect(() => {
        loadData && loadData();
    }, [loadData]);

    if (isInitialLoad) {
        return <LoadingAnimation classname="loading-anim-box-margin-top" type="pi-spinner" />;
    }

    return (
        <div className="basket-page-container">
            <CheckoutTimeline
                step={STEP_BASKET}
                distributionId={distributionId}
                assemblyId={currentAssembly?.id}
            />
            <div className={isLoading ? 'filteredList is-loading' : 'filteredList'}>
                {FeaturesService.isFeatureActive('payment_issues_banner', user) && (
                    <Banner>{trans('banner.paymentIssue')}</Banner>
                )}
                <div className="basket-page-producer-notice">
                    {isSmallWidth && <InlineSVG src={ProducerIllustrationSVG} />}
                    <div className="basket-page-producer-notice-texts">
                        <PIText family="mr" size="16px" lineHeight="24px" bold color="gray2">
                            {trans('basket.producer.share.notice.title')}
                        </PIText>
                        <PIText size="14px" lineHeight="20px" color="gray2">
                            {trans('basket.producer.share.notice.content')}
                        </PIText>
                    </div>
                </div>
                <Heading size={4} rank={2} productIdentity>
                    {trans('basket.myBasket')}
                </Heading>
                <div className="basket-page-baskets">
                    {currentOrder?.countItems > 0 && (
                        <BasketOrder
                            key={currentOrder.id}
                            order={currentOrder}
                            distribution={currentOrder?.distribution}
                            getQueryParameters={() =>
                                AppChannel.reqres.request('special:query:parameters')
                            }
                            openCancelOrderModal={openCancelOrderModal}
                            openResetToCartOrderModal={openResetToCartOrderModal}
                        />
                    )}
                    {sortedOrders
                        .filter(order => order.id !== currentOrder?.id && order.countItems > 0)
                        .map(order => {
                            return (
                                <BasketOrder
                                    key={order.id}
                                    order={order}
                                    distribution={order?.distribution}
                                    getQueryParameters={() =>
                                        AppChannel.reqres.request('special:query:parameters')
                                    }
                                    openCancelOrderModal={openCancelOrderModal}
                                    openResetToCartOrderModal={openResetToCartOrderModal}
                                />
                            );
                        })}
                </div>
                <TermsFooter />
            </div>
            {orderCancelModal && (
                <CancelContainerModal
                    onClose={closeCancelOrderModal}
                    show={!!orderCancelModal}
                    orderId={orderCancelModal}
                />
            )}

            {orderResetToCartModal && (
                <OrderResetToCartModal
                    show={!!orderResetToCartModal}
                    onClose={closeResetToCartOrderModal}
                    orderId={orderResetToCartModal}
                />
            )}
        </div>
    );
};

export default BasketsContainer;
