import React, { useEffect, useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import useI18n from 'hooks/I18n/useI18n';
import useAnalytics from 'hooks/Analytics/useAnalytics.js';
import useResponsive from 'hooks/Navigation/useResponsive';

import { filerPhoto } from 'modules/utils/filer';
import { OTHER_PRODUCTS, PRODUCT_PAGE } from 'modules/originUtils.js';
import { hasAvailableStock } from 'modules/stockUtils';

import { getProductTag } from 'models/tag';
import { getUniqLabelsList } from 'models/products';

import { Heading, Grid } from 'components/ui';
import ProductCard, {
    MODE_HORIZONTAL as CARD_HORIZONTAL,
    MODE_LARGE,
} from 'components/Sale/ProductIdentity/ProductCard.jsx';
import PIText from 'components/ProductIdentity/Text.jsx';
import Offer, {
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL,
    BUTTON_LARGE,
    PRODUCT_TEMPLATE,
} from 'components/Product/ProductIdentity/Offer.jsx';
import ProductLabel, { MEDIUM_SIZE } from 'components/Product/ProductLabel.jsx';
import MultiOffersModal from 'components/OffersModal/ProductIdentity/MultiOffersModal.jsx';
import OtherProductInformations from 'components/Product/ProductIdentity/OtherProductInformations.jsx';
import FarmHeader from 'components/Sale/ProductIdentity/FarmHeader.jsx';
import Icon from 'components/Icon.jsx';

import Tag from 'src/components/atoms/Tag/Tag.jsx';
import Button, {
    LINK_MODE,
    SMALL_SIZE,
    SECONDARY_MODE,
} from 'src/components/atoms/Button/Button.jsx';
import Favourite from 'src/components/atoms/Favourite/Favourite.jsx';

import ArrowLeft from 'app/assets/new-design/images/icons/caret-left.svg';
import TroncatedDescription from 'components/Sale/ProductIdentity/TroncatedDescription.jsx';
import ProductFoodFeedback from 'components/Product/ProductIdentity/FoodFeedback/ProductFoodFeedback.jsx';

const getProductSrc = (photoId, productCategoryId) => {
    if (!photoId) {
        const categoryId = productCategoryId || 'default';
        return `/assets/images/products/pi/product-${categoryId}.png`;
    }
    return filerPhoto(photoId, 'large', 'product');
};

export const ProductPageContainer = props => {
    const {
        distributionId,
        product,
        addOfferToBasket,
        productTypes,
        otherProductsOfFarm,
        assembly,
        lastVisitedCategoryId,
        onClickOnProduct,
        history,
    } = props;
    const { trans, transChoice } = useI18n();
    const { analytics } = useAnalytics();
    const isSmallWidth = useResponsive();
    const [showMoreProducts, setShowMoreProducts] = useState(false);
    const limitProductToShow = useMemo(() => (isSmallWidth ? 6 : 12), [isSmallWidth]);
    const [addToCartModalIsOpen, setAddToCartModalIsOpen] = useState(false);
    const isMultiOffer = useMemo(() => product.offers.length > 1, [product]);

    const tag = useMemo(() => !isMultiOffer && getProductTag(trans, transChoice)(product), [
        product,
        trans,
        transChoice,
        isMultiOffer,
    ]);

    const toggleAddToCartModal = useCallback(() => {
        history.replace({ search: location.search });
        setAddToCartModalIsOpen(!addToCartModalIsOpen);
    }, [addToCartModalIsOpen, history]);

    const renderMultiOffersModal = () =>
        addToCartModalIsOpen && (
            <MultiOffersModal
                addOfferToBasket={addOfferToBasket}
                allProductTypesIndexedById={productTypes}
                closeModal={toggleAddToCartModal}
                distributionId={distributionId}
                product={product}
                actionOrigin={PRODUCT_PAGE}
            />
        );

    const defaultSrc = useMemo(
        () =>
            getProductSrc(product?.photoId, R.prop('categoryId', productTypes[product?.type?.id])),
        [product, productTypes]
    );

    const showMultiOfferButton = useMemo(() => product.offers.length > 4, [product]);

    const sortedOtherProductsOfFarm = R.sortWith([R.ascend(a => (hasAvailableStock(a) ? 0 : 1))])(
        otherProductsOfFarm
    );

    useEffect(() => {
        analytics.trackProductPageView(product, assembly);
    }, [product, assembly, analytics]);

    const labels = getUniqLabelsList(product);

    return (
        <div className="pi-productPage">
            <div className="pi-product-back-button">
                <Button
                    size={SMALL_SIZE}
                    mode={LINK_MODE}
                    onClick={() => {
                        if (lastVisitedCategoryId) {
                            history.push(`/category/${lastVisitedCategoryId}`, {
                                toFarm: product.farm.id,
                            });
                        } else {
                            history.push('/');
                        }
                    }}
                >
                    <Icon size="xx-large" src={ArrowLeft} />
                    <PIText family="inter" color="gray8">
                        {trans('products.productPage.backToSale')}
                    </PIText>
                </Button>
            </div>
            <div
                className={classNames('pi-product-main-informations', {
                    'single-offer': !isMultiOffer,
                })}
            >
                <div className="pi-product-main-picture">
                    <img className="pi-product-image" src={defaultSrc} alt={name} />
                    <div>
                        {labels.slice(0, 2).map((label, index) => {
                            if (labels.length > 2 && index >= 1) {
                                return (
                                    <ProductLabel
                                        key="product-label-count"
                                        count={product.labels.length - 1}
                                        size={MEDIUM_SIZE}
                                    />
                                );
                            }
                            return (
                                <ProductLabel
                                    key={label.id}
                                    name={label.name}
                                    photoId={label.photoId}
                                    size={MEDIUM_SIZE}
                                />
                            );
                        })}
                    </div>
                    {tag.label && (
                        <Tag
                            className={classNames('image-tag', tag.type)}
                            readOnly
                            label={
                                <PIText family="inter" size="14px" lineHeight="16px">
                                    {tag.label}
                                </PIText>
                            }
                        ></Tag>
                    )}
                </div>
                <div className="pi-product-main-title">
                    <Heading rank={3} size={4} bold family="inter" productIdentity>
                        {product.name}
                    </Heading>
                    <PIText className="pi-product-farm-name" family="inter" color="gray9">
                        {product.farm.name}
                    </PIText>
                    {product.heart_count ? (
                        <Favourite
                            label={`${product.heart_count} ${transChoice(
                                'product.favourites',
                                product.heart_count
                            )}`}
                        />
                    ) : null}
                    {!isSmallWidth && (
                        <TroncatedDescription
                            title={trans('products.productPage.description.title')}
                            description={product.description}
                            maxLine="auto"
                        />
                    )}
                </div>
                <div className="pi-product-offer-col-1">
                    {product.offers.slice(0, 2).map(offer => (
                        <Offer
                            template={PRODUCT_TEMPLATE}
                            buttonSize={(!isMultiOffer || !isSmallWidth) && BUTTON_LARGE}
                            offer={offer}
                            orientation={
                                isMultiOffer ? ORIENTATION_HORIZONTAL : ORIENTATION_VERTICAL
                            }
                            distributionId={distributionId}
                            addOfferToBasket={addOfferToBasket}
                            product={product}
                            showTag={isMultiOffer}
                            isMultiOffer={isMultiOffer}
                            actionOrigin={PRODUCT_PAGE}
                        />
                    ))}
                </div>
                {product.offers.length > 2 ? (
                    <div className="pi-product-offer-col-2">
                        {product.offers.slice(2, showMultiOfferButton ? 3 : 4).map(offer => (
                            <Offer
                                template={PRODUCT_TEMPLATE}
                                buttonSize={!isSmallWidth && BUTTON_LARGE}
                                key={offer.id}
                                offer={offer}
                                mode={ORIENTATION_HORIZONTAL}
                                distributionId={distributionId}
                                addOfferToBasket={addOfferToBasket}
                                product={product}
                                isMultiOffer={isMultiOffer}
                                actionOrigin={PRODUCT_PAGE}
                            />
                        ))}
                        {showMultiOfferButton && (
                            <div className="pi-product-show-multi-offer">
                                <Button
                                    onClick={toggleAddToCartModal}
                                    mode={SECONDARY_MODE}
                                    size="small"
                                >
                                    {trans('productPage.button.showAllOffers')}
                                </Button>
                            </div>
                        )}
                    </div>
                ) : null}
                {isSmallWidth && (
                    <>
                        <Heading
                            className="pi-productPage-description-title"
                            productIdentity
                            family="inter"
                            rank={2}
                            size={4}
                        >
                            {trans('products.productPage.description.title')}
                        </Heading>
                        <TroncatedDescription
                            title={trans('products.productPage.description.title')}
                            description={product.description}
                        />
                    </>
                )}
            </div>
            <div className="pi-other-informations-wrapper">
                <OtherProductInformations
                    product={product}
                    assembly={assembly}
                ></OtherProductInformations>
            </div>
            <ProductFoodFeedback productId={product.id} />
            <div className="pi-product-farm">
                <FarmHeader
                    title={trans('products.productPage.descriptionTitle')}
                    subtitle={product.farm.name}
                    image={product.farm.farmerPhotoId}
                    description={product.farm.description}
                />
            </div>
            <div className="productPage-contentAndOtherProducts">
                {sortedOtherProductsOfFarm.length > 0 && (
                    <>
                        <Heading size={3} rank={4} productIdentity>
                            {trans('products.productPage.otherProducts')}
                        </Heading>
                        <Grid>
                            {sortedOtherProductsOfFarm
                                .slice(
                                    0,
                                    showMoreProducts
                                        ? sortedOtherProductsOfFarm.length
                                        : limitProductToShow
                                )
                                .map(otherProduct => {
                                    return (
                                        <ProductCard
                                            addOfferToBasket={addOfferToBasket}
                                            allProductTypesIndexedById={productTypes}
                                            distributionId={distributionId}
                                            product={otherProduct}
                                            actionOrigin={OTHER_PRODUCTS}
                                            mode={isSmallWidth ? CARD_HORIZONTAL : MODE_LARGE}
                                            key={otherProduct.id}
                                            onClick={() => onClickOnProduct(otherProduct.id)}
                                            showProducer={false}
                                        />
                                    );
                                })}
                        </Grid>
                        {sortedOtherProductsOfFarm.length > limitProductToShow && (
                            <Button
                                className={showMoreProducts && 'open'}
                                mode={LINK_MODE}
                                onClick={() => setShowMoreProducts(!showMoreProducts)}
                            >
                                {showMoreProducts
                                    ? trans('global.show.less')
                                    : trans('global.show.more')}
                                <Icon src={ArrowLeft} size="x-large" />
                            </Button>
                        )}
                    </>
                )}
            </div>
            {renderMultiOffersModal()}
        </div>
    );
};

ProductPageContainer.propTypes = {
    product: PropTypes.object.isRequired,
    distributionId: PropTypes.number.isRequired,
    addOfferToBasket: PropTypes.func.isRequired,
    productTypes: PropTypes.object.isRequired,
    assembly: PropTypes.object.isRequired,
    otherProductsOfFarm: PropTypes.array.isRequired,
    lastVisitedCategoryId: PropTypes.string,
    onClickOnProduct: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};
export default ProductPageContainer;
