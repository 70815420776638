import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { formatFullName } from 'models/users';
import useI18n from 'hooks/I18n/useI18n';
import { getArticles, getPromotedArticles } from 'models/helpCenter';
import HelpCenter from './HelpCenter.jsx';
import ContextService from 'app/scripts/entities/context/context.service';
import { shouldDeployProductIdentity } from 'modules/productIdentity/themeDeploymentManager';
import PIHelpCenter from 'components/Navigation/ProductIdentity/HelpCenter/HelpCenter.jsx';
import { INITIAL_LOADING, LOADING_UPDATE, READY } from 'modules/utils/ajaxStatuses';
import { getLocalizedTime } from 'modules/utils/dateAndTime';
import { isHostOfAssembly } from 'models/users';
import { USER, FARMER, MEMBER, HOST } from 'modules/utils/userTypes';
import AppChannel from 'modules/channels/App';

const getSupportContact = (currentAssembly, currentUser, farm, trans, i18n) => {
    const defaultLabel = trans('helpCenter.contact.timeRange', {
        '%start%': getLocalizedTime(i18n, '10:00:00'),
        '%end%': getLocalizedTime(i18n, '19:00:00'),
    });
    if (currentAssembly && isHostOfAssembly(currentUser, currentAssembly.id)) {
        return {
            ...(currentAssembly.happyculteur || {}),
            header: trans('helpCenter.contact.team.contact'),
            label: defaultLabel,
        };
    } else if (currentUser.isFarmer && currentAssembly === null) {
        return {
            ...(farm.happyculteur || {}),
            header: trans('helpCenter.contact.support.contact'),
            label: defaultLabel,
        };
    }
    return {
        header: trans('helpCenter.contact.host.contact'),
        label: formatFullName(currentAssembly.leader),
        phone: currentAssembly.leader.phone,
        photo: currentAssembly.leader.photo,
        hostId: currentAssembly.leader.id,
    };
};

const getUserType = (currentAssembly, currentUser) => {
    if (currentAssembly && isHostOfAssembly(currentUser, currentAssembly.id)) {
        return HOST;
    } else if (currentUser.isFarmer && currentAssembly === null) {
        return FARMER;
    } else if (!currentUser.isFarmer && currentAssembly === null) {
        return USER;
    } else if (currentUser.anonymous) {
        return USER;
    }
    return MEMBER;
};
const HelpCenterContainer = props => {
    const { currentAssembly, user, farm } = props;
    const { trans, i18n } = useI18n();
    const [searchValue, setSearchValue] = useState('');
    const [articlesState, setArticlesState] = useState({
        articles: [],
        status: INITIAL_LOADING,
        promotedArticles: [],
    });
    const productIdentity = shouldDeployProductIdentity(
        user,
        ContextService.getCurrentContext().toJSON().type
    );
    const supportContact = getSupportContact(currentAssembly, user, farm, trans, i18n);
    const userType = useMemo(() => getUserType(currentAssembly, user), [currentAssembly, user]);
    const fetchPromotedArticles = useCallback(() => {
        return getPromotedArticles(userType, i18n).then(articles => {
            setArticlesState(state => ({
                ...state,
                status: READY,
                promotedArticles: articles,
            }));
        });
    }, [i18n, userType]);
    const loadingPromotedArticles = useCallback(() => {
        setArticlesState(state => ({
            ...state,
            status: LOADING_UPDATE,
        }));
        fetchPromotedArticles();
    }, [fetchPromotedArticles]);
    const fetchArticles = useCallback(() => {
        return getArticles(searchValue, userType, i18n).then(articles => {
            setArticlesState(state => ({
                ...state,
                status: READY,
                articles: articles.results,
            }));
        });
    }, [i18n, searchValue, userType]);
    const loadingArticles = useCallback(
        newSearchValue => {
            if (newSearchValue.length > 0) {
                return fetchArticles();
            }
            return setArticlesState(state => ({
                ...state,
                status: READY,
                articles: [],
            }));
        },
        [fetchArticles]
    );

    const onMessage = useCallback(() => {
        if (currentAssembly && !isHostOfAssembly(user, currentAssembly.id)) {
            return AppChannel.vent.trigger('analytics:newHelpCenterContactCard:contactHost', {
                contactedUserId: currentAssembly.leader.id,
                contactingUserId: user.id,
            });
        }
        return null;
    }, [currentAssembly, user]);
    const onSearch = useCallback(newSearchValue => {
        if (newSearchValue.length > 0) {
            setSearchValue(newSearchValue);
            setArticlesState(state => ({ ...state, status: LOADING_UPDATE }));
        } else {
            setSearchValue(newSearchValue);
        }
    }, []);
    const HelpCenterComponent = productIdentity ? PIHelpCenter : HelpCenter;
    useEffect(() => {
        loadingPromotedArticles();
    }, [loadingPromotedArticles]);
    return (
        <HelpCenterComponent
            {...articlesState}
            searchValue={searchValue}
            userType={userType}
            onSearch={onSearch}
            loadingArticles={loadingArticles}
            localeCode={i18n}
            supportContact={supportContact}
            onMessage={onMessage}
        />
    );
};

HelpCenterContainer.propTypes = {
    farm: PropTypes.object,
    currentAssembly: PropTypes.object,
    user: PropTypes.object.isRequired,
};

export default HelpCenterContainer;
