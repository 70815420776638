import React, { useCallback, useMemo } from 'react';
import InlineSVG from 'svg-inline-react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import useI18n from 'hooks/I18n/useI18n';

import { userSelector } from 'modules/currentUser';
import { validateOrder, revalidateOrder } from 'modules/orders';
import { getLocalizedDate, getLocalizedTime } from 'modules/utils/dateAndTime';

import { isBasket } from 'models/orders';
import { saleHasEnded } from 'models/distributions';

import PIText from 'components/ProductIdentity/Text.jsx';
import Button, { SECONDARY_MODE, SMALL_SIZE } from 'src/components/atoms/Button/Button.jsx';
import Utils from 'modules/utils';

import ErrorIcon from 'app/assets/new-design/images/icons/error.svg';
import ValidateIcon from 'app/assets/new-design/images/icons/validate.svg';
import LoadingGrayIcon from 'app/assets/new-design/images/icons/loading-gray.svg';
import LoadingOrangeIcon from 'app/assets/new-design/images/icons/loading-orange.svg';
import PickupIcon from 'app/assets/new-design/images/icons/basket-pickup.svg';
import HomeDeliveryIcon from 'app/assets/new-design/images/icons/basket-home-delivery.svg';

const { apiLinkTo } = Utils;

const BasketOrderStatus = props => {
    const {
        order,
        distribution,
        getQueryParameters,
        hasDeliveryOptions,
        openCancelOrderModal,
        openResetToCartOrderModal,
        className,
        deliveryOptions,
    } = props;
    const { trans, i18n, transChoice } = useI18n();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(userSelector);

    const validateOrderDispatch = useCallback(
        () => dispatch(validateOrder(order.id, hasDeliveryOptions)),
        [order, hasDeliveryOptions, dispatch]
    );
    const revalidateOrderDispatch = useCallback(() => dispatch(revalidateOrder(order.id)), [
        dispatch,
        order.id,
    ]);

    const [statusLabel, buttonLabel, action, Icon, buttonMode] = useMemo(() => {
        if (isBasket(order)) {
            return [
                trans('order.status.inProgress'),
                trans('basket.button.deliveryPage'),
                order.countItems > 0 && user.anonymous
                    ? () => history.push(`/checkout/${order.uuid}/pickup`)
                    : validateOrderDispatch,
                <InlineSVG src={LoadingGrayIcon} />,
            ];
        }
        // Pending order
        if (order.state === 3) {
            return [
                trans('order.status.pending'),
                !saleHasEnded(distribution) &&
                    order.canBeResetToCart &&
                    trans('order.reset_to_cart.title'),
                !saleHasEnded(distribution) && order.canBeResetToCart && openResetToCartOrderModal,
                <InlineSVG src={LoadingOrangeIcon} color="red" />,
                SECONDARY_MODE,
            ];
        }
        // Validated order
        if (order.state === 4 || order.state === 5) {
            return [
                trans('order.status.validated'),
                !saleHasEnded(distribution)
                    ? trans('order.cancellation.title')
                    : trans('distributions.downloadOrder'),
                !saleHasEnded(distribution)
                    ? openCancelOrderModal
                    : () =>
                          window.open(
                              apiLinkTo(`orders/${order.id}.pdf{?q*}`, {
                                  hash: { q: getQueryParameters() },
                              }),
                              '_blank'
                          ),
                <InlineSVG src={ValidateIcon} color="red" />,
                SECONDARY_MODE,
            ];
        }
        if (order.isCancelledByTheMember || order.state === 9) {
            return [
                trans('distributions.finalize.reasons.canceled'),
                trans('basket.backToSale'),
                () =>
                    history.push(
                        `/assemblies/${distribution.hiveId}/collections/${distribution.id}/products`
                    ),
                <InlineSVG src={ErrorIcon} color="red" />,
            ];
        }
        // Cancelled, but not by member
        if (order.state === 7 || order.state === 2) {
            return [
                trans('order.status.canceled'),
                trans('basket.validateAgain'),
                revalidateOrderDispatch,
                <InlineSVG src={ErrorIcon} color="red" />,
            ];
        }
        return null;
    }, [
        order,
        trans,
        validateOrderDispatch,
        revalidateOrderDispatch,
        distribution,
        getQueryParameters,
        history,
        openCancelOrderModal,
        openResetToCartOrderModal,
        user,
    ]);
    const countDeliveryOfferType =
        deliveryOptions.filter(delivery => ['deliveryOffer'].includes(delivery.type)).length || 0;

    const showAssemblyDelivery =
        !!deliveryOptions.find(delivery => ['assembly', 'pickup'].includes(delivery.type)) &&
        (['assembly', 'pickup'].includes(order.deliveryOption.type) || isBasket(order));

    const showHomeDelivery =
        countDeliveryOfferType > 0 &&
        (['deliveryOffer'].includes(order.deliveryOption.type) || isBasket(order));

    return (
        <div className={classNames('basket-order-status-container', className)}>
            <div className="basket-order-status-content">
                <PIText
                    className="basket-order-status-title"
                    family="mr"
                    size="18px"
                    lineHeight="26px"
                    bold
                    color="gray2"
                >
                    {Icon}
                    {statusLabel}
                </PIText>
                <div className="delivery-option">
                    {showAssemblyDelivery && (
                        <PIText
                            family="mr"
                            size="14px"
                            lineHeight="20px"
                            weight={600}
                            color="gray2"
                        >
                            <InlineSVG src={PickupIcon} />
                            {transChoice('pickups.pickupPoints', countDeliveryOfferType)}
                        </PIText>
                    )}
                    {showHomeDelivery && (
                        <PIText
                            family="mr"
                            size="14px"
                            lineHeight="20px"
                            weight={600}
                            color="gray2"
                        >
                            <InlineSVG src={HomeDeliveryIcon} />
                            {trans('global.homeDelivery')}
                        </PIText>
                    )}
                </div>
                {order.status !== 'basket' && (
                    <>
                        <PIText
                            className="basket-order-status-date"
                            size="14px"
                            lineHeight="20px"
                            color="gray2"
                            weight={500}
                        >
                            {trans('assembly.distributionTimeWithDay', {
                                '%weekday%': getLocalizedDate(
                                    i18n,
                                    'I',
                                    distribution.distributionDate
                                ),
                                '%startHour%': getLocalizedTime(i18n, distribution.timeStart),
                                '%endHour%': getLocalizedTime(i18n, distribution.timeEnd),
                            })}
                        </PIText>
                        <div className="basket-order-status-hive-address">
                            <PIText size="14px" lineHeight="20px" color="gray2">
                                {distribution.place.name}
                            </PIText>
                            <PIText size="14px" lineHeight="20px" color="gray2">
                                {distribution.place.address.street}
                            </PIText>
                            <PIText
                                size="14px"
                                lineHeight="20px"
                                color="gray2"
                            >{`${distribution.place.address.city.zipCode} ${distribution.place.address.city.name}`}</PIText>
                        </div>
                    </>
                )}
            </div>
            {buttonLabel && (
                <Button size={SMALL_SIZE} disabled={!action} onClick={action} mode={buttonMode}>
                    {buttonLabel}
                </Button>
            )}
        </div>
    );
};

BasketOrderStatus.propTypes = {
    getQueryParameters: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    distribution: PropTypes.object.isRequired,
    hasDeliveryOptions: PropTypes.bool.isRequired,
    openCancelOrderModal: PropTypes.func,
    openResetToCartOrderModal: PropTypes.func,
    className: PropTypes.string,
    deliveryOptions: PropTypes.object,
};

export default BasketOrderStatus;
