import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import useResponsive from 'hooks/Navigation/useResponsive';
import { useLocation } from 'react-router-dom';

import { currentAssemblySelector } from 'modules/navigation';
import AssemblyInfos from 'components/Navigation/ProductIdentity/AssemblyInfos.jsx';
import HelpButton from 'components/Navigation/ProductIdentity/HelpButton.jsx';
import AccountButton from 'components/Navigation/ProductIdentity/AccountButton.jsx';
import { userSelector } from 'modules/currentUser';
import {
    showImmediately as showPanelImmediately,
    getVisiblePanel,
    HELP_PANEL,
} from 'modules/visiblePanel';

const PublicHeader = () => {
    const isSmallWidth = useResponsive();
    const currentUser = useSelector(userSelector);
    const visiblePanel = useSelector(getVisiblePanel);
    const currentAssembly = useSelector(currentAssemblySelector);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const farm = R.path(['farm', 0], currentUser);

    const displayHelpPanel = visiblePanel === HELP_PANEL;

    const isOnAssemblyHomepage = useMemo(() => pathname === '/', [pathname]);

    const openMenu = (e, panel) => {
        e.preventDefault();
        dispatch(showPanelImmediately(panel));
    };
    const openHelpMenu = e => openMenu(e, HELP_PANEL);
    useEffect(() => {
        document.body.classList.add('productIdentity');
    });
    return (
        <div className="pi-public-header">
            <nav>
                {currentAssembly ? <AssemblyInfos /> : null}
                <div className="pi-public-header-right">
                    {
                        <HelpButton
                            currentAssembly={currentAssembly}
                            open={displayHelpPanel}
                            openHelpMenu={openHelpMenu}
                            currentUser={currentUser}
                            farm={farm}
                            productIdentity
                        />
                    }
                    <AccountButton
                        isSmallWidth={isSmallWidth}
                        isOnHeader={isOnAssemblyHomepage}
                        shouldShowProductIdentity
                    />
                </div>
            </nav>
        </div>
    );
};

export default PublicHeader;
