import { pipe, map, uniq, curry, filter } from 'ramda';

import { isStockIntoAlertZone, totalAvailableStock } from 'modules/stockUtils';
import { captureException } from 'modules/error-reporting';

const tagsLabel = {
    'stock-alert': (trans, transChoice, { availableStock }) =>
        availableStock
            ? transChoice('salePage.stockAlert', availableStock, {
                  '%stockAvailable%': availableStock,
              })
            : trans('salePage.filter.stockAlert'),
    over_production: trans => trans(`offer.promotion.reason.over_production`),
    expiration_date: trans => trans(`offer.promotion.reason.expiration_date`),
    to_be_discovered: trans => trans(`offer.promotion.reason.to_be_discovered`),
    'new-assembly': trans => trans('salePage.product.new'),
    'already-bought': trans => trans('salePage.alreadyBought'),
    'open-sale': trans => trans('hive.openSale'),
    end_of_season: trans => trans(`offer.promotion.reason.end_of_season`),
    anti_waste: trans => trans(`offer.promotion.reason.anti_waste`),
    custom_reason: trans => trans(`offer.promotion.reason.custom`),
    'bulk-offer': trans => trans(`offer.bulk`),
    'engaged-price': trans => trans(`offer.engaged_price`),
};

export const PROMOTION_KEY = 'promotion';
export const NEW_ASSEMBLY_KEY = 'new-assembly';
export const STOCK_ALERT_KEY = 'stock-alert';
export const BULK_OFFER_KEY = 'bulk-offer';
export const OPEN_SALE_KEY = 'open-sale';
export const ENGAGED_PRICE_KEY = 'engaged-price';
export const CUSTOM_REASON_KEY = 'custom_reason';
export const ANTI_WASTE_KEY = 'anti_waste';
export const DISCOVERED_KEY = 'to_be_discovered';

const tagsConditions = {
    [CUSTOM_REASON_KEY]: {
        condition: product =>
            product.offers.findIndex(offer => offer.promotion?.type === 'custom_reason') !== -1,
        key: () => 'custom_reason',
    },
    [ANTI_WASTE_KEY]: {
        condition: product =>
            product.offers.findIndex(offer => offer.promotion?.type === 'anti_waste') !== -1,
        key: () => 'anti_waste',
    },
    [DISCOVERED_KEY]: {
        condition: product =>
            product.offers.findIndex(offer => offer.promotion?.type === 'to_be_discovered') !== -1,
        key: () => 'to_be_discovered',
    },
    [PROMOTION_KEY]: {
        condition: product => !!product.offers[0].promotion,
        key: product => product.offers[0].promotion?.type,
    },
    [NEW_ASSEMBLY_KEY]: {
        condition: product => !!product.isNewInAssembly,
        key: () => 'new-assembly',
    },
    [STOCK_ALERT_KEY]: {
        condition: product =>
            isStockIntoAlertZone(totalAvailableStock(product)) && product.offers.length <= 1,
        key: () => 'stock-alert',
    },
    [BULK_OFFER_KEY]: {
        condition: product => product.offers[0].isbulk,
        key: () => 'bulk-offer',
    },
    [OPEN_SALE_KEY]: {
        condition: (product, openSale) => openSale,
        key: () => 'open-sale',
    },
    [ENGAGED_PRICE_KEY]: {
        condition: product => product.has_engaged_price,
        key: () => 'engaged-price',
    },
};

export const getProductTagKey = (product, openSale, hasGoodDealMode) => {
    const tagOrder = hasGoodDealMode
        ? [CUSTOM_REASON_KEY, ANTI_WASTE_KEY, DISCOVERED_KEY, BULK_OFFER_KEY, ENGAGED_PRICE_KEY]
        : [
              PROMOTION_KEY,
              NEW_ASSEMBLY_KEY,
              STOCK_ALERT_KEY,
              BULK_OFFER_KEY,
              OPEN_SALE_KEY,
              ENGAGED_PRICE_KEY,
          ];
    for (let i = 0; i < tagOrder.length; i++) {
        const { condition, key } = tagsConditions[tagOrder[i]];
        if (condition(product, openSale)) {
            return key(product);
        }
    }
    return null;
};

export const getOfferTagKey = offer => {
    if (offer.promotion) {
        return offer.promotion.type;
    }
    if (offer.isbulk) {
        return 'bulk-offer';
    }
    if (offer.isengagedprice) {
        return 'engaged-price';
    }

    return null;
};

export const getUniqTagKeyList = (products, openSale, hasGoodDealMode) =>
    pipe(
        map(product => getProductTagKey(product, openSale, hasGoodDealMode)),
        uniq,
        filter(str => !!str)
    )(products);

export const applyTagTrans = curry((trans, transChoice, tagName, product = null) => {
    let translation;
    try {
        translation = tagsLabel[tagName](trans, transChoice, {
            availableStock: product ? totalAvailableStock(product) : null,
        });
    } catch (e) {
        captureException(`${e} tagName: ${tagName}`);
    } finally {
        return translation || null;
    }
});

const createLabel = (trans, transChoice, tagKey, product = null) => {
    if (!tagKey) {
        return {};
    }
    const label = applyTagTrans(trans, transChoice, tagKey, product);
    return {
        type: tagKey,
        label,
    };
};

export const getProductTag = (trans, transChoice) => (product, openSale, hasGoodDealMode) => {
    return createLabel(
        trans,
        transChoice,
        getProductTagKey(product, openSale, hasGoodDealMode),
        product
    );
};

export const getOfferTag = (trans, transChoice) => offer => {
    return createLabel(trans, transChoice, getOfferTagKey(offer));
};
