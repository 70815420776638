import URI from 'URIjs';
import _ from 'underscore';
import { persistCampaign } from 'modules/campaignTracker';
import { getAppId } from 'app/scripts/config/analytics';
import * as R from 'ramda';
import memoizeLast from 'modules/memoizeLast';
import { isAndroidTWAContext } from 'modules/androidTWA';

const queryKeysToCampaignKeysMap = {
    utm_campaign: 'campaignName',
    utm_medium: 'campaignMedium',
    utm_source: 'campaignSource',
    utm_content: 'campaignContent',
    utm_term: 'campaignKeyword',
};

function getBrandName() {
    const shortLocaleBasedBrandNames = {
        be: 'Boerenburen',
        de: 'Marktschwaermer',
        en: 'The food assembly',
        nl: 'Buurderij',
        fr: 'La Ruche qui dit Oui !',
        it: 'Alveare che dice si',
        es: 'La colmena que dice si',
    };

    let brandName = 'The food assembly';
    const locale = location.pathname.split('/')[1];
    const shortLocale = locale.split('-')[0];

    if (shortLocale in shortLocaleBasedBrandNames) {
        brandName = shortLocaleBasedBrandNames[shortLocale];
    }

    return brandName;
}

function extractCampaignParameters(query) {
    const campaignParameters = {};
    const restParameters = {};
    _.each(query, (value, key) => {
        const settingKey = queryKeysToCampaignKeysMap[key];
        if (settingKey) {
            campaignParameters[settingKey] = value;
        } else {
            restParameters[key] = value;
        }
    });
    return {
        campaignParameters,
        restParameters,
    };
}

// this value should not change during a session so it can be computed once at startup
const dimension7Value = isAndroidTWAContext() ? 'androidTWA' : 'default';

export class Analytics {
    constructor(appId) {
        if (!window.ga || !appId) {
            return;
        }
        this.appId = appId;
        const uri = new URI();
        const parseAsMap = true;
        const query = uri.search(parseAsMap);

        const clientId = query.cid || query.clientId;

        if (clientId) {
            window.history.replaceState({}, document.title, uri.removeSearch('cid').toString());
            window.ga('create', appId, 'auto', { clientId });
        } else {
            window.ga('create', appId, 'auto');
        }

        window.ga('require', 'ec', 'ec.js');
        window.ga('set', 'forceSSL', true);
        window.ga('set', 'anonymizeIp', true);

        this._customDimensions = {
            dimension1: 'visitor', // role
            dimension2: 'undefined', // current hiveId context
            dimension3: 'undefined', // userId
            dimension4: 'undefined', // distributionId
            dimension5: 'undefined', // ActiveFeatures
            dimension6: 'undefined', // Add to basket origin
            dimension7: dimension7Value, // Web page usage context (default for any browser or androidTWA for Android TWA shell)
        };

        this.setCampaignParameters();

        function rawSendPageView(
            page,
            language,
            dimension1,
            dimension2,
            dimension3,
            dimension4,
            dimension5,
            dimension6,
            dimension7
        ) {
            window.ga('send', 'pageview', {
                page,
                language,
                dimension1,
                dimension2,
                dimension3,
                dimension4,
                dimension5,
                dimension6,
                dimension7,
            });
        }

        this.memoizedSendPageView = memoizeLast((...args) => R.join('_', args), rawSendPageView);
    }

    setCampaignParameters() {
        // We need to remove the campain parameters so that the router starts with a 'clean' URL
        const uri = new URI();
        const parseAsMap = true;
        const query = uri.search(parseAsMap);
        const { campaignParameters, restParameters } = extractCampaignParameters(query);

        if (_.isEmpty(campaignParameters)) {
            return;
        }

        _.each(campaignParameters, (value, key) => {
            window.ga('set', key, value);
        });

        persistCampaign(JSON.stringify(campaignParameters));

        window.history.replaceState({}, document.title, uri.search(restParameters).toString());
    }

    sendPageView() {
        const uri = new URI();
        const language = uri.segment(0);
        const page = uri.path().substr(1) + uri.search();

        // @note: all of this is to avoid consecutive calls because of
        // mixed routing logic marionette/react-router
        // and maybe avoid others fuck ups
        this.memoizedSendPageView(
            page,
            language,
            this._customDimensions.dimension1,
            this._customDimensions.dimension2,
            this._customDimensions.dimension3,
            this._customDimensions.dimension4,
            this._customDimensions.dimension5,
            this._customDimensions.dimension6,
            this._customDimensions.dimension7
        );
    }

    /**
     * Send an event to Google Analytics.
     * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
     *
     * @param {String} category
     * @param {String} action
     * @param {String} label
     * @param {Number} value
     */
    sendEvent(category, action, label, value) {
        return window.ga('send', 'event', category, action, label, value, this._customDimensions);
    }

    /**
     * Track an event through Google Tag Manager (GTM).
     *
     * @see https://segment.com/docs/spec/track/
     *
     * @param {String} eventName
     * @param {Object} properties
     */
    track(eventName, properties) {
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'UniversalEvent',
                eventCategory: properties.category,
                eventAction: eventName,
                eventLabel: properties.label,
                eventValue: properties.value,
                gaAppId: this.appId,
            });
    }

    updateRole(role) {
        this._customDimensions.dimension1 = role;
    }

    clearRole() {
        this._customDimensions.dimension1 = 'visitor';
        this._customDimensions.dimension2 = 'undefined';
        this._customDimensions.dimension3 = 'undefined';
        this._customDimensions.dimension4 = 'undefined';
        this._customDimensions.dimension5 = 'undefined';
        this._customDimensions.dimension6 = 'undefined';
        this._customDimensions.dimension7 = dimension7Value;
    }

    updateUserId(id) {
        this._customDimensions.dimension3 = String(id) || 'undefined';

        // Google Tag Manager
        /* global dataLayer */

        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'login',
                country_name: getBrandName(),
                isAndroidTWA: isAndroidTWAContext(),
            });
    }

    trackUserRegistration() {
        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'register',
                country_name: getBrandName(),
                isAndroidTWA: isAndroidTWAContext(),
            });
    }

    updateAssemblyId(context) {
        this._customDimensions.dimension2 =
            (context.get('data') && String(context.get('data').hiveId)) || 'undefined';
    }

    trackProductPageView(product, assembly) {
        const price = product.offers[0].price;

        dataLayer && dataLayer.push && dataLayer.push({ ecommerce: null }); // clear ecommerce property before fill it with fresh data

        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'view_item',
                ecommerce: {
                    currency: price.currency,
                    value: parseInt(price.amount, 10) / 100,
                    country_name: getBrandName(),
                    assemblyId: assembly.id,
                    assemblyName: assembly.name,
                    isAndroidTWA: isAndroidTWAContext(),
                    items: [
                        {
                            item_id: product.id,
                            item_name: product.name,
                            item_brand: product.farm.name,
                            item_category: product.category_name_en,
                            item_category2: product.type_name_en,
                            item_category4: product.type.root_id,
                            item_category5: product.type.id,
                            item_variant: product.offers[0].id,
                            affiliation: assembly.name,
                            price: parseInt(price.amount, 10) / 100,
                        },
                    ],
                },
            });
    }

    trackMapSearch(autocompleteSelectedItemName) {
        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'search_map',
                country_name: getBrandName(),
                search_term_map: autocompleteSelectedItemName,
                isAndroidTWA: isAndroidTWAContext(),
            });
    }

    trackCartPageView(lastCreatedBasket) {
        dataLayer && dataLayer.push && dataLayer.push({ ecommerce: null }); // clear ecommerce property before fill it with fresh data

        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'view_cart',
                ecommerce: {
                    assemblyId: lastCreatedBasket.distribution.hiveId,
                    assemblyName: lastCreatedBasket.distribution.assemblyName,
                    value: parseInt(lastCreatedBasket.totalPrice.amount, 10) / 100,
                    country_name: getBrandName(),
                    isAndroidTWA: isAndroidTWAContext(),
                },
            });
    }

    trackOrdersCancellation(order) {
        dataLayer && dataLayer.push && dataLayer.push({ ecommerce: null }); // clear ecommerce property before fill it with fresh data

        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'refund',
                ecommerce: {
                    currency: order.totalPrice.currency,
                    value: parseInt(order.totalPrice.amount, 10) / 100,
                    transaction_id: order.id,
                    assemblyId: order.distribution.hiveId,
                    assemblyName: order.distribution.assemblyName,
                    country_name: getBrandName(),
                    isAndroidTWA: isAndroidTWAContext(),
                },
            });
    }

    trackBeginCheckout(data) {
        const context = data.context;
        const currency = data.currency;

        dataLayer && dataLayer.push && dataLayer.push({ ecommerce: null }); // clear ecommerce property before fill it with fresh data

        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'begin_checkout',
                ecommerce: {
                    value: parseInt(data.amount, 10) / 100,
                    transaction_id: context?.orderId,
                    orderId: context?.orderId,
                    assemblyId: context?.assembly?.id,
                    assemblyName: context?.assembly?.name,
                    country_name: context?.brandName,
                    isAndroidTWA: isAndroidTWAContext(),
                    items:
                        data.context?.items &&
                        data.context.items.map(item => ({
                            item_id: item.product.id,
                            item_name: item.product.name,
                            item_brand: item.producer.name,
                            item_brand_id: item.producer.id,
                            item_category: item.category.name,
                            item_category4: item.category.id,
                            item_category2: item.product.type_name,
                            item_category5: item.product.type_id,
                            item_variant: item.product.offer.id,
                            affiliation: context?.assembly?.name,
                            price: parseInt(item.price.amount, 10) / 100,
                            quantity: item.quantity,
                        })),
                    currency,
                },
            });
    }

    trackPaymentFormSubmission(amount, currency, paymentType) {
        dataLayer && dataLayer.push && dataLayer.push({ ecommerce: null }); // clear ecommerce property before fill it with fresh data

        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'add_payment_info',
                ecommerce: {
                    currency,
                    value: parseInt(amount, 10) / 100,
                    paymentMethod: paymentType,
                    country_name: getBrandName(),
                    isAndroidTWA: isAndroidTWAContext(),
                },
            });
    }

    trackViewItemList(listId, listName, assembly, items) {
        dataLayer && dataLayer.push && dataLayer.push({ ecommerce: null }); // clear ecommerce property before fill it with fresh data

        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'view_item_list',
                ecommerce: {
                    item_list_id: listId,
                    item_list_name: listName,
                    assemblyId: assembly.id,
                    assemblyName: assembly.name,
                    country_name: getBrandName(),
                    isAndroidTWA: isAndroidTWAContext(),
                    items: items.map(item => ({
                        item_id: item.id,
                        item_name: item.name,
                        item_brand: item.farm.name,
                        item_category: item.category_name_en,
                        item_category2: item.type_name_en,
                        item_category4: item.type.root_id,
                        item_category5: item.type.id,
                        affiliation: assembly.name,
                    })),
                },
            });
    }

    trackAddToBasket(product, offer, assembly, actionOrigin) {
        dataLayer && dataLayer.push && dataLayer.push({ ecommerce: null }); // clear ecommerce property before fill it with fresh data

        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                    currency: offer.price.currency,
                    value: parseInt(offer.price.amount, 10) / 100,
                    assemblyId: assembly.id,
                    assemblyName: assembly.name,
                    country_name: getBrandName(),
                    action_origin: actionOrigin,
                    isAndroidTWA: isAndroidTWAContext(),
                    items: [
                        {
                            item_id: product.id,
                            item_name: product.name,
                            item_brand: product.farm.name,
                            item_category: product.category_name_en,
                            item_category2: product.type_name_en,
                            item_category4: product.type.root_id,
                            item_category5: product.type.id,
                            item_variant: offer.id,
                            affiliation: assembly.name,
                            price: parseInt(offer.price.amount, 10) / 100,
                        },
                    ],
                },
            });
    }

    trackAssemblyVisit(assembly) {
        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'visit_assembly',
                assemblyId: assembly.id,
                assemblyName: assembly.name,
                country_name: getBrandName(),
                isAndroidTWA: isAndroidTWAContext(),
            });
    }

    trackAssemblyJoin(assemblyId, assemblyName) {
        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'user_joins_assembly',
                country_name: getBrandName(),
                isAndroidTWA: isAndroidTWAContext(),
                assemblyId,
                assemblyName,
            });
    }

    eCommerceProductAdded(data) {
        this._customDimensions.dimension4 = String(data.distributionId);
        this._customDimensions.dimension6 = String(data.actionOrigin);
        window.ga('set', '&cu', data.currency);
        window.ga('ec:setAction', 'editProductQuantity');
        this.sendEvent('Ecommerce', 'editProductQuantity');
    }

    eCommerceCheckout(data) {
        this._customDimensions.dimension4 = String(data.distributionId);

        window.ga('ec:setAction', 'checkout');
        this.sendEvent('Ecommerce', 'checkout');
    }

    eCommercePayment(data) {
        const context = data.context;

        this._customDimensions.dimension4 = String(data.context?.distributionId);
        const revenue = parseInt(data.revenue, 10) / 100;
        const currency = data.currency;
        window.ga('set', '&cu', currency);
        window.ga('ec:setAction', 'purchase', {
            id: context?.orderId,
            revenue,
        });
        this.sendEvent('Ecommerce', 'transaction');

        // Google Tag Manager
        /* global dataLayer */

        dataLayer && dataLayer.push && dataLayer.push({ ecommerce: null }); // clear ecommerce property before fill it with fresh data

        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    value: revenue,
                    transaction_id: context?.orderId,
                    coupon: context?.couponCode,
                    orderId: context?.orderId,
                    assemblyId: context?.assembly?.id,
                    assemblyName: context?.assembly?.name,
                    paymentMethod: context?.paymentMethod,
                    country_name: context?.brandName,
                    isAndroidTWA: isAndroidTWAContext(),
                    items:
                        data.context?.items &&
                        data.context.items.map(item => ({
                            item_id: item.product.id,
                            item_name: item.product.name,
                            item_brand: item.producer.name,
                            item_brand_id: item.producer.id,
                            item_category: item.category.name,
                            item_category4: item.category.id,
                            item_category2: item.product.type_name,
                            item_category5: item.product.type_id,
                            item_variant: item.product.offer.id,
                            affiliation: context?.assembly?.name,
                            price: parseInt(item.price.amount, 10) / 100,
                            quantity: item.quantity,
                        })),
                    revenue,
                    currency,
                },
                orderId: context?.orderId,
                revenue,
                currency,
            });
    }

    showJoinAssemblyForm(assemblyId) {
        this.sendEvent('Register', 'showjoinform', assemblyId);
    }

    submitJoinAssemblyForm(assemblyId) {
        this.sendEvent('Register', 'submitjoinform', assemblyId);
    }

    submitAssemblyPageJoinForm(assemblyId) {
        this.sendEvent('Register', 'submitassemblypagejoinform', assemblyId);
    }

    downloadFinancialDocument(documentType) {
        this.sendEvent('Professionals', 'downloadfinancialdocument', documentType);
    }

    downloadProducerOrders(type) {
        this.sendEvent('Producers', 'downloadOrder', type);
    }

    assemblyCardOpenComposeMessageHost({ contactingUserId, contactedUserId }) {
        this.sendEvent(
            'Hosts',
            'openComposeMessageHost',
            `host_${contactingUserId}_contacting_${contactedUserId}`
        );
    }

    assemblyCardCallHost({ contactingUserId, contactedUserId }) {
        this.sendEvent(
            'Hosts',
            'callHost',
            `host_${contactingUserId}_contacting_${contactedUserId}`
        );
    }

    userInputProductTypeFilter({ userInput, selectedProductName }) {
        this.sendEvent(
            'FarmSearch',
            'userInputProductTypeFilter',
            `userInput_${userInput}_userInputLength_${userInput.length}_selectedProductName_${selectedProductName}`
        );
    }

    trackHttpError(status) {
        this.sendEvent('HttpError', `httpError${status}`);
    }

    producerCardCallProducer({ contactingUserId, contactedUserId }) {
        this.sendEvent(
            'Producers',
            'callProducer',
            `producer_${contactingUserId}_contacting_${contactedUserId}`
        );
    }

    producerCardOpenComposeMessageProducer({ contactingUserId, contactedUserId }) {
        this.sendEvent(
            'Producers',
            'openComposeMessageProducer',
            `producer_${contactingUserId}_contacting_${contactedUserId}`
        );
    }

    newHelpCenterComposeMessageHost({ contactingUserId, contactedUserId }) {
        this.sendEvent(
            'NewHelpCenter',
            'contactHost',
            `host_${contactingUserId}_contacting_${contactedUserId}`
        );
    }

    newHelpCenterOpenHelpPanel() {
        this.sendEvent('NewHelpCenter', 'openHelpPanel');
    }

    newHelpCenterSearchOnHelpCenter() {
        this.sendEvent('NewHelpCenter', 'searchOnHelpCenter');
    }

    newHelpCenterVisitArticle() {
        this.sendEvent('NewHelpCenter', 'visitArticle');
    }

    registerFromAnonymousPayment() {
        this.sendEvent('Register', 'registeredFromAnonymousPayment');

        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'register',
                country_name: getBrandName(),
                isAndroidTWA: isAndroidTWAContext(),
            });
    }

    trackMapNavigation() {
        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'navigate_map',
                country_name: getBrandName(),
                isAndroidTWA: isAndroidTWAContext(),
            });
    }

    productSearch({ query, resultCount }) {
        /* global dataLayer */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'UniversalEvent',
                eventCategory: 'Member',
                eventAction: 'collection_productSearched',
                eventLabel: query,
                eventValue: resultCount,
                gaAppId: this.appId,
            });
    }

    addToBasketFromSearch({ query, offerPriceAmount }) {
        /* global dataLayer */
        dataLayer &&
            dataLayer.push &&
            dataLayer.push({
                event: 'UniversalEvent',
                eventCategory: 'Member',
                eventAction: 'collection_addToBasketFromSearch',
                eventLabel: query,
                eventValue: offerPriceAmount,
                gaAppId: this.appId,
            });
    }
}

let singleton;

if (___SERVER___) {
    const noop = () => {};
    singleton = new Proxy(
        {},
        {
            get: function getter(target, key) {
                if (key === '__esModule') {
                    return false;
                }
                return noop;
            },
        }
    );
} else {
    singleton = new Analytics(getAppId());
}

// @note: singleton
export default singleton;
