import * as R from 'ramda';

import { offerPromotionIsEnoughToBeInGoodDeal } from 'modules/utils/promotion.js';

import { getProductTagKey } from 'models/tag';

export const ALL_PRODUCTS_FILTER = 'all';
export const ALL_TAGS_FILTER = 'allTags';
export const ORGANIC_FILTER = 'organicOnly';
export const ALL_PRODUCTS_CATEGORY = '_all';
export const ALREADY_BOUGHT_CATEGORY = 'alreadybought';
export const HIGHLIGHTED_CATEGORY = 'highlighted';
export const FRUITS_AND_VEGETABLES = 1;
export const MEAT = 257;
export const DAIRY_AND_EGGS = 239;
export const GOOD_DEAL_CATEGORY = 'goodDeals';

export const getProductsFromCategoryOrSubcategory = (products, categoryId, subCategoryId) => {
    if (categoryId === ALL_PRODUCTS_CATEGORY) {
        return products;
    }
    if (categoryId === HIGHLIGHTED_CATEGORY) {
        return R.filter(R.propEq('highlighted', true), products);
    }
    if (categoryId === ALREADY_BOUGHT_CATEGORY) {
        return R.filter(R.propEq('alreadyBought', true))(products);
    }
    if (categoryId === GOOD_DEAL_CATEGORY) {
        const productsFiltered = JSON.parse(JSON.stringify(products)).filter(
            product =>
                (product.has_promoted || product.has_engaged_price || product.has_bulk) &&
                product.offers.some(
                    offer =>
                        offerPromotionIsEnoughToBeInGoodDeal(offer) ||
                        offer.isengagedprice ||
                        offer.isbulk
                )
        );
        return productsFiltered.map(product => {
            product.offers = product.offers.filter(
                offer =>
                    offerPromotionIsEnoughToBeInGoodDeal(offer) ||
                    offer.isengagedprice ||
                    offer.isbulk
            );
            return product;
        });
    }
    if (subCategoryId && subCategoryId !== GOOD_DEAL_CATEGORY) {
        return R.filter(R.pathEq(['type', 'parent_id'], subCategoryId))(products);
    }
    return R.filter(R.propEq('root_type_id', categoryId))(products);
};

export const filterProducts = R.curry((categoryId, subCategoryId, labelsFilter, products) => {
    const productsFromCategoryOrSubcategory = getProductsFromCategoryOrSubcategory(
        products,
        categoryId,
        subCategoryId
    );
    if (labelsFilter === ORGANIC_FILTER) {
        return R.filter(R.propEq('isOrganic', true))(productsFromCategoryOrSubcategory);
    }
    return productsFromCategoryOrSubcategory;
});

const isFarmEqual = farmId => R.pathEq(['farm', 'id'], farmId);

export const memoizeProductsOfFarm = R.memoizeWith(
    (currentFarmId, products) => `${currentFarmId}${products.map(product => product.id).join('-')}`,
    (currentFarmId, products) => R.filter(isFarmEqual(currentFarmId), products)
);

export const filterByCategory = (products, categoryId) =>
    categoryId ? getProductsFromCategoryOrSubcategory(products, categoryId) : products;

export const filterByPromotion = (products, promotionKey, hasGoodDealMode) =>
    promotionKey
        ? products.filter(
              product =>
                  getProductTagKey(product, false, hasGoodDealMode) === promotionKey ||
                  promotionKey === ALL_TAGS_FILTER ||
                  !promotionKey
          )
        : products;

const isProductFromFarm = farm => product => R.pathEq(['farm', 'id'], farm.id, product);

export const getCurrentFarmsOrderedByPriority = (farms, products) => {
    const hasProductInProductsList = farm => R.any(isProductFromFarm(farm))(products);

    const currentFarms = R.filter(hasProductInProductsList)(R.values(farms));
    const byPriority = R.descend(R.prop('priority'));
    return R.sort(byPriority, currentFarms);
};

export const filterSubcategoriesOfCategory = (
    category,
    products,
    categoryLevel = 'root_type_id'
) => ({
    ...category,
    subCategories: category.subCategories.filter(subCategory =>
        products.some(product => product[categoryLevel] === subCategory.id)
    ),
});
