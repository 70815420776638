import { get, post } from 'modules/api';

export const HIVE_RECIPIENT = 'hive';
export const FARM_RECIPIENT = 'farm';

export const getNewsfeeds = recipientType => get(`newsfeeds?recipientType=${recipientType}`);

export const getUnreadNewsfeedCount = recipientType =>
    get(`newsfeeds/count-unread?recipientType=${recipientType}`);

export const postReadNewsfeed = recipientType => post('newsfeeds/read', { recipientType });
