import PropTypes from 'prop-types';
import React from 'react';
import BoxSection from 'components/BoxSection.jsx';
import classnames from 'classnames';

const Box = ({
    children,
    className,
    hasSections,
    id,
    shadow,
    responsive,
    padding,
    isDisabled,
    transparent,
}) => {
    const hasSectionsPropOrInChildren =
        typeof hasSections !== 'undefined'
            ? hasSections
            : React.Children.toArray(children).some(child => child.type === BoxSection);

    const classes = classnames({
        box: true,
        'with-padding': !hasSectionsPropOrInChildren,
        [`box--${padding}Padding`]: padding,
        'box--shadow': shadow,
        'box--plain-xs': responsive === 'plain',
        'box--transparent-xs': responsive === 'transparent',
        'box-disabled': isDisabled,
        'box--transparent': transparent,
    });

    return (
        <div className={classnames(classes, className)} id={id}>
            {children}
        </div>
    );
};

Box.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    hasSections: PropTypes.bool,
    id: PropTypes.string,
    shadow: PropTypes.bool,
    responsive: PropTypes.oneOf(['box', 'plain', 'transparent']),
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    isDisabled: PropTypes.bool,
    transparent: PropTypes.bool,
};

Box.defaultProps = {
    shadow: false,
    responsive: 'box',
    padding: 'medium',
};

export default Box;
