import * as R from 'ramda';
import { get, patch, post } from 'modules/api';
import getClientId from 'modules/analytics/getClientIdGA';
import { BEGIN, REVERT, COMMIT } from 'redux-optimist';
import { uniqueId } from 'underscore';
import Backbone from 'backbone';
import { isAndroidWebview } from 'app/scripts/config/analytics';
import { LOADING } from '../../actions';
import { DELIVERY_OFFER } from 'modules/utils/deliveryOptions.js';
import {
    addOfferAnonymous,
    getBasketAnonymous,
    getOrdersAnonymous,
    patchItemAnonymous,
} from './anonymousBasket';
import {
    actionTypes,
    GLOBAL_STATE_KEY,
    isOrderItemLoading,
    getOrderEntity,
    getOrderItemEntity,
    isOrderLoading,
    getBasketOfDistribution,
    isBasketOfDistributionLoading,
    makeTemporaryOrderId,
    makeTemporaryOrderItemId,
} from './index';
import { userSelector } from 'modules/currentUser';
import { isAndroidTWAContext } from 'modules/androidTWA';

function redirectToDeliveryPage(orderId, pageToRedirect = 'pickup') {
    Backbone.history.navigate(`orders/${orderId}/${pageToRedirect}`, {
        trigger: true,
    });
}

export const createBasketRequestSent = () => ({
    type: actionTypes.CREATE_BASKET.REQUEST,
});

export const createBasketRequestSucceeded = () => ({
    type: actionTypes.CREATE_BASKET.SUCCESS,
});

// @mutation
// If order has no id, (basket that has not been created), give it one
function splitOrderAndOrderItem(orders) {
    const ordersMap = {};
    const orderItemsMap = {};
    const orderIds = orders.map(order => {
        const orderId = order.id || makeTemporaryOrderId(order.distributionId);
        ordersMap[orderId] = order;
        order.items = R.pipe(
            R.propOr([], 'items'),
            R.map(item => {
                orderItemsMap[item.id] = item;
                return item.id;
            })
        )(order);
        return orderId;
    });
    return [orderIds, ordersMap, orderItemsMap];
}

const fetchOrdersSuccess = orders_ => dispatch => {
    const [orderIds, orders, orderItems] = splitOrderAndOrderItem(orders_);
    return dispatch({
        payload: {
            entities: {
                orders,
                orderItems,
            },
            result: orderIds,
        },
        type: actionTypes.FETCH_ORDERS.SUCCESS,
    });
};

const fetchOrdersFailure = error => dispatch => {
    dispatch({
        type: actionTypes.FETCH_ORDERS.FAILURE,
        payload: {
            error: error.message || 'Unexpected error',
        },
    });
};

const addOffer = (dispatch, state, distributionId, offerId, quantity, searchQueryId) => {
    const user = userSelector(state);
    return user.anonymous
        ? addOfferAnonymous(dispatch, state, distributionId, offerId, quantity, searchQueryId)
        : post(`distributions/${distributionId}/basket/items`, {
              offer: offerId,
              quantity,
              search_query_id: searchQueryId,
          });
};

const getBasket = (distributionId, state) => {
    const user = userSelector(state);
    return user.anonymous
        ? getBasketAnonymous(distributionId)
        : get(`distributions/${distributionId}/basket`);
};

const getOrders = state => {
    const user = userSelector(state);
    return user.anonymous ? getOrdersAnonymous() : get('orders/');
};

const patchItem = (state, distributionId, offerId, quantity, searchQueryId) => {
    const user = userSelector(state);
    return user.anonymous
        ? patchItemAnonymous(state, distributionId, offerId, quantity, searchQueryId)
        : patch(`distributions/${distributionId}/basket/items/${offerId}`, {
              quantity,
              search_query_id: searchQueryId,
          });
};

export const fetchOrders = () => (dispatch, getState) => {
    if (getState()[GLOBAL_STATE_KEY].fetchStatus === LOADING) {
        return Promise.resolve(undefined);
    }
    dispatch({ type: actionTypes.FETCH_ORDERS.REQUEST });
    const state = getState();

    return getOrders(state)
        .then(R.pipe(R.propOr([], 'orders')))
        .then(
            orders_ => dispatch(fetchOrdersSuccess(orders_)),
            error => dispatch(fetchOrdersFailure(error))
        );
};

const fetchBasketSuccess = (dispatch, payload) => basket => {
    const [result, orders, orderItems] = splitOrderAndOrderItem([basket]);
    dispatch({
        payload: {
            ...payload,
            previousEntityIds: payload.entityIds,
            entityIds: result,
            entities: {
                orders,
                orderItems,
            },
        },
        type: actionTypes.FETCH_BASKET.SUCCESS,
    });
};

const fetchBasketFailure = (dispatch, payload) => error =>
    dispatch({
        type: actionTypes.FETCH_BASKET.FAILURE,
        payload: {
            ...payload,
            error: error.message || 'Unexpected error',
        },
    });

export const fetchBasket = distributionId => (dispatch, getState) => {
    const state = getState();
    const _basket = getBasketOfDistribution(state, { distributionId });
    const isFetching = isBasketOfDistributionLoading(state, {
        distributionId,
        orderId: _basket && _basket.id,
    });

    if (isFetching) {
        return undefined;
    }

    const payload = {
        distributionId,
        entityIds: [(_basket && _basket.id) || makeTemporaryOrderId(distributionId)],
    };

    dispatch({
        type: actionTypes.FETCH_BASKET.REQUEST,
        payload,
    });

    return getBasket(distributionId, state).then(
        fetchBasketSuccess(dispatch, payload),
        fetchBasketFailure(dispatch, payload)
    );
};

const handleOrderValidationError = (
    dispatch,
    payload,
    { isOrder = false, shouldRedirectToDeliveryPage = false } = {}
) => err => {
    if (err.status === 409) {
        const [, orders, orderItems] = splitOrderAndOrderItem([err.responseJSON]);
        dispatch({
            type: actionTypes.STOCK_ERROR,
            payload: {
                ...payload,
                entities: {
                    orders,
                    orderItems,
                },
            },
        });

        Backbone.history.navigate('basket', {
            trigger: true,
        });
    } else if (
        err.status === 400 &&
        err.responseJSON &&
        err.responseJSON.error === 'MissingUserInformation'
    ) {
        let url = `complete-profile?orderUuid=${err.responseJSON.orderUuid}&orderId=${payload.orderId}`;

        if (isOrder) {
            url += '&revalidate=true';
        }

        if (shouldRedirectToDeliveryPage) {
            url += '&delivery=true';
        }

        dispatch({
            type: actionTypes.MISSING_USER_INFORMATION_ERROR,
            payload: {
                ...payload,
                error: err.responseJSON,
            },
        });

        Backbone.history.navigate(url, {
            trigger: true,
        });
    } else {
        dispatch({
            type: actionTypes.VALIDATE_ORDER.FAILURE,
            error: true,
            payload: {
                ...payload,
                error: err.message || 'Something went wrong',
            },
        });
    }
};

// @FIXME (sinewyk): factorize this and revalidateOrder ?
export const validateOrder = (orderId, hasDeliveryOptions, pageToRedirect) => (
    dispatch,
    getState
) => {
    const previousState = getState();

    if (isOrderLoading(previousState, { orderId })) {
        return undefined;
    }

    const distributionId = getOrderEntity(previousState, { orderId }).distributionId;

    const payload = {
        distributionId,
        orderId,
    };

    dispatch({
        type: actionTypes.VALIDATE_ORDER.REQUEST,
        payload,
        analytics: ({ GA }) => {
            GA.eCommerceCheckout({ distributionId });
        },
    });

    return getClientId().then(clientId => {
        const _url = `${location.protocol}//${
            location.port ? `${location.hostname}:${location.port}` : location.hostname
        }${Backbone.history.root}basket`;

        const params = {
            urlAccept: _url,
            urlDecline: _url,
            urlCancel: _url,
            clientId,
        };

        if (isAndroidWebview()) {
            params.androidWebview = 1;
        }

        if (isAndroidTWAContext()) {
            params.androidTWA = 1;
        }

        return post(`distributions/${distributionId}/basket/confirm`, params).then(
            response => {
                if (hasDeliveryOptions) {
                    redirectToDeliveryPage(response.orderId, pageToRedirect);
                } else {
                    window.location = response.paymentFormUrl;
                }
            },
            handleOrderValidationError(dispatch, payload, {
                isOrder: false,
                shouldRedirectToDeliveryPage: hasDeliveryOptions,
            })
        );
    });
};

export const revalidateOrder = (orderId, distributionId) => (dispatch, getState) => {
    const previousState = getState();

    if (isOrderLoading(previousState, { orderId })) {
        return undefined;
    }

    const payload = {
        distributionId: distributionId || getOrderEntity(previousState, { orderId }).distributionId,
        orderId,
    };

    dispatch({
        type: actionTypes.REVALIDATE_ORDER.REQUEST,
        payload,
        analytics: ({ GA }) => {
            GA.eCommerceCheckout({ distributionId });
        },
    });

    return getClientId().then(clientId => {
        const _url = `${location.protocol}//${
            location.port ? `${location.hostname}:${location.port}` : location.hostname
        }${Backbone.history.root}basket`;

        const params = {
            urlAccept: _url,
            urlDecline: _url,
            urlCancel: _url,
            clientId,
        };

        if (isAndroidWebview()) {
            params.androidWebview = 1;
        }

        if (isAndroidTWAContext()) {
            params.androidTWA = 1;
        }

        const validationUrl = `orders/${orderId}/payments`;

        return post(validationUrl, params).then(
            response => {
                window.location = response.paymentFormUrl;
            },
            handleOrderValidationError(dispatch, payload, {
                isOrder: true,
                shouldRedirectToDeliveryPage: false,
            })
        );
    });
};

export const fetchOrdersAndValidateBasket = basketUuid => (dispatch, getState) => {
    if (getState()[GLOBAL_STATE_KEY].fetchStatus === LOADING) {
        return Promise.resolve(undefined);
    }
    dispatch({ type: actionTypes.FETCH_ORDERS.REQUEST });
    const state = getState();

    return getOrders(state)
        .then(R.pipe(R.propOr([], 'orders')))
        .then(orders_ => {
            dispatch(fetchOrdersSuccess(orders_));

            return orders_;
        })
        .then(R.find(R.propEq('uuid', basketUuid)))
        .then(
            basket => {
                if (basket) {
                    const redirectToDelivery = basket.deliveryOption.type === DELIVERY_OFFER;
                    return dispatch(validateOrder(basket.id, redirectToDelivery, 'pickup'));
                }

                return Promise.resolve();
            },
            error => dispatch(fetchOrdersFailure(error))
        );
};

export const cancelOrder = (order, cancellationReason) => (dispatch, getState) => {
    const previousState = getState();
    const orderId = order.id;

    if (isOrderLoading(previousState, { orderId })) {
        return undefined;
    }

    const payload = {
        orderId,
        cancellationReason,
    };

    dispatch({ type: actionTypes.CANCEL_ORDER.REQUEST, payload });

    return post(`orders/${orderId}/cancel`, { cancellationReason }).then(
        () =>
            dispatch({
                type: actionTypes.CANCEL_ORDER.SUCCESS,
                payload,
                analytics: ({ GA }) => {
                    GA.trackOrdersCancellation(order);
                },
            }),
        err =>
            dispatch({
                type: actionTypes.CANCEL_ORDER.FAILURE,
                payload: {
                    ...payload,
                    error: err.message,
                },
            })
    );
};

export const resetOrderToCart = orderId => (dispatch, getState) => {
    const previousState = getState();

    if (isOrderLoading(previousState, { orderId })) {
        return undefined;
    }

    const payload = {
        orderId,
    };

    dispatch({ type: actionTypes.RESET_ORDER_TO_CART.REQUEST, payload });

    return post(`orders/${orderId}/reset-to-cart`).then(
        () => dispatch({ type: actionTypes.RESET_ORDER_TO_CART.SUCCESS, payload }),
        err =>
            dispatch({
                type: actionTypes.RESET_ORDER_TO_CART.FAILURE,
                payload: {
                    ...payload,
                    error: err.message,
                },
            })
    );
};

const addOfferSuccess = (
    dispatch,
    payload,
    offerId,
    quantity,
    distributionId,
    actionOrigin
) => order => {
    const [, orders, orderItems] = splitOrderAndOrderItem([order]);
    const newOrderItem = R.pipe(
        R.values,
        R.filter(x => x.offer.id === offerId),
        R.head
    )(orderItems);
    dispatch({
        type: actionTypes.ADD_OFFER.SUCCESS,
        payload: {
            ...payload,
            previousEntityIds: payload.entityIds,
            entityIds: [newOrderItem.id],
            entities: {
                orders,
                orderItems,
            },
        },
        analytics: ({ GA }) => {
            GA.eCommerceProductAdded({
                id: newOrderItem.product.id,
                price: newOrderItem.offer.price.amount,
                quantity,
                name: newOrderItem.product.name,
                variant: newOrderItem.offer.id,
                currency: newOrderItem.offer.price.currency,
                distributionId,
                actionOrigin,
            });
        },
    });
    dispatch(createBasketRequestSucceeded());
    return order;
};

const addOfferFailure = (dispatch, payload) => error =>
    dispatch({
        type: actionTypes.ADD_OFFER.FAILURE,
        payload: {
            ...payload,
            error,
        },
    });

const updateOrderItemSuccess = (
    dispatch,
    getState,
    payload,
    orderItemId_,
    quantity,
    distributionId,
    actionOrigin
) => order => {
    const [, orders, orderItems] = splitOrderAndOrderItem([order]);
    dispatch({
        type: actionTypes.UPDATE_ORDER_ITEM.SUCCESS,
        payload: {
            ...payload,
            entities: {
                orders,
                orderItems,
            },
        },
        optimist: {
            type: COMMIT,
            id: payload.transactionId,
        },
        analytics: ({ GA }) => {
            const orderItem = getOrderItemEntity(getState(), { orderItemId: orderItemId_ });
            GA.eCommerceProductAdded({
                id: orderItem.product.id,
                price: orderItem.offer.price.amount,
                quantity,
                name: orderItem.product.name,
                variant: orderItem.offer.id,
                currency: orderItem.offer.price.currency,
                distributionId,
                actionOrigin,
            });
        },
    });
    return order;
};

const updateOrderItemFailure = (dispatch, payload) => error =>
    dispatch({
        type: actionTypes.UPDATE_ORDER_ITEM.FAILURE,
        payload: {
            ...payload,
            error,
        },
        optimist: {
            type: REVERT,
            id: payload.transactionId,
        },
    });

export const editBasketOfferQuantity = (
    distributionId,
    offerId,
    quantity,
    actionOrigin,
    orderItemId,
    searchQueryId
) => (dispatch, getState) => {
    const state = getState();

    let shouldCreateOrderItem = false;

    const orderItemId_ =
        orderItemId ||
        (() =>
            R.pipe(
                R.propOr([], 'items'),
                R.filter(R.pathEq(['offer', 'id'], offerId)),
                R.head,
                R.propOr(undefined, 'id')
            )(getBasketOfDistribution(state, { distributionId }) || {}))() ||
        ((shouldCreateOrderItem = true), makeTemporaryOrderItemId(distributionId, offerId));

    const payload = {
        distributionId,
        offerId,
        quantity,
        entityIds: [orderItemId_],
        transactionId: uniqueId(),
    };

    if (isOrderItemLoading(state, { orderItemId: orderItemId_ })) {
        return undefined;
    }

    if (state[GLOBAL_STATE_KEY].isCreatingBasket) {
        return undefined;
    }

    if (shouldCreateOrderItem) {
        dispatch({
            type: actionTypes.ADD_OFFER.REQUEST,
            payload,
        });

        // @NOTE: This api is an add operation, you can only add
        // sending quantity 1 while there is already 1 = 2
        return addOffer(dispatch, state, distributionId, offerId, quantity, searchQueryId).then(
            addOfferSuccess(dispatch, payload, offerId, quantity, distributionId, actionOrigin),
            addOfferFailure(dispatch, payload)
        );
    }

    dispatch({
        type: actionTypes.UPDATE_ORDER_ITEM.REQUEST,
        payload,
        optimist: {
            type: BEGIN,
            id: payload.transactionId,
        },
    });

    // @NOTE: this api is a set operation, sending 1 while there is already 1 does nothing
    // sending 0 delete the orderItem
    return patchItem(state, distributionId, offerId, quantity, searchQueryId).then(
        updateOrderItemSuccess(
            dispatch,
            getState,
            payload,
            orderItemId_,
            quantity,
            distributionId,
            actionOrigin
        ),
        updateOrderItemFailure(dispatch, payload)
    );
};

export const closeStockAlertModal = orderId => ({
    type: actionTypes.CLOSE_STOCK_ERROR_MODAL,
    payload: {
        orderId,
    },
});

export function confirmBasket(distributionId) {
    return getClientId().then(clientId => {
        const _url = `${location.protocol}//${
            location.port ? `${location.hostname}:${location.port}` : location.hostname
        }${Backbone.history.root}basket`;

        const params = {
            urlAccept: _url,
            urlDecline: _url,
            urlCancel: _url,
            clientId,
        };

        if (isAndroidWebview()) {
            params.androidWebview = 1;
        }

        if (isAndroidTWAContext()) {
            params.androidTWA = 1;
        }

        return post(`distributions/${distributionId}/basket/confirm`, params);
    });
}
